<template>
	<v-dialog :width="mwstylesettings.SmallDialogWidth" v-model="dialog">
		<template v-slot:activator="{ on }">
			<v-btn color="igblack" dark small v-on="on"><v-icon class="mr-2" small>mdi-plus</v-icon> {{mwtr.NewSectionNavItem}}</v-btn>
		</template>
		<v-card>
			<v-card-title>{{mwtr.NewSectionNavItem}}</v-card-title>
			<v-card-text>
				<p>
					{{mwtr.NewSectionBlub}}
				</p>
				<v-text-field :label="mwtr.SectionName" v-model="name"></v-text-field>
				
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="create">{{mwtr.AddSectionButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "NewSection", 
		props: {
			department: {}
		},
		data: () => {
			return {
				name: "", 
				dialog: false
			}
		},
	
		methods: {
			create(){
				var self = this;
				var data = {
					name: this.name,
				}
				self.$store.dispatch("sections/new", data).then( () => {
					self.dialog = false
				})
			}, 
		},
		// created(){
			
		// }

	}
// </script>"