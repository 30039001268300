<template>
	<v-sheet color="transparent" class="pa-4">
		<p class="text-right">
			<v-btn color="primary" to="section-wizard">Section Wizard</v-btn>
		</p>
		<v-card flat color="secondary">
			<v-data-table :headers="headers" :items="items">
				<template v-slot:item.hasSection="{ item }">
					<v-icon class="pl-3" v-if="item.section">mdi-check-circle</v-icon>
				</template>				
				<template v-slot:item.button="{ item }">
					<ListButtons :id="item.id" collection="sections"></ListButtons>
				</template>
			</v-data-table>			
		</v-card>
		<Export :rows="items"></Export>
		<p class="text-right">
			<NewSection></NewSection>
		</p>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ListButtons from "@/components/admin/lists/ListButtons"
	import Export from "@/components/admin/panels/Export"
	import NewSection from "@/components/admin/forms/NewSection"

	export default {
		name: "Sections", 
		props: {
			
		},
		data: () => {
			return {
				search: ""
			}
		},
		components: {
			ListButtons,
			Export,
			NewSection
		},
		computed: {
			...Vuex.mapState({
				sections: state => state.sections
			}), 
			headers(){
				return [
					{value: "name", text: "Name"},
					{value: "button", text: " "},
				]
			}, 
			items(){
				var sections = Object.values(this.sections)
				return sections;
			}
		},
		methods: {
			newSectionWithParts(){
				console.log( "abc" )
			}
		},
		created(){
			this.$store.dispatch("sections/fetchAll")
      }

	}
// </script>"